import Vue from 'vue'
import dateTimeWithTimeZone from '@/utils/date/dateTimeWithTimeZone'

export default {
  getTrialsBlankForms() {
    return new Promise((resolve, reject) => {
      const currentClientTime = dateTimeWithTimeZone()

      Vue.$http
        .get(`/web/trial/blank-form`)
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getTrialsBlankForms error', error, {
            currentClientTime,
          })
          reject(error)
        })
    })
  },
  getBlankForms(siteNo: string) {
    return new Promise((resolve, reject) => {
      const currentClientTime = dateTimeWithTimeZone()

      Vue.$http
        .get(`/web/sites/${siteNo}/blank-form`)
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getBlankForms error', error, {
            siteNo,
            currentClientTime,
          })
          reject(error)
        })
    })
  },
  downloadBlankForm(siteNo: string, blankFormId: number) {
    return new Promise((resolve, reject) => {
      const currentClientTime = dateTimeWithTimeZone()

      Vue.$http
        .post(
          '/web/sites/' + siteNo + '/blank-form/' + blankFormId,
          { currentClientTime },
          { responseType: 'arraybuffer' }
        )
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('downloadBlankForm error', error, {
            siteNo,
            blankFormId,
            currentClientTime,
          })
          reject(error)
        })
    })
  },
  downloadTrialBlankForm(blankFormId: number) {
    return new Promise((resolve, reject) => {
      const currentClientTime = dateTimeWithTimeZone()

      Vue.$http
        .post(
          '/web/trial/blank-form/' + blankFormId,
          { currentClientTime },
          { responseType: 'arraybuffer' }
        )
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('downloadTrialBlankForm error', error, {
            blankFormId,
            currentClientTime,
          })
          reject(error)
        })
    })
  },
}
